import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/layout';
import { MobileInfoSection } from '../components/mobile-info-section/mobile-info-section';
import {
  InfoColumn,
  InfoColumnSection,
} from '../components/info-column/info-column';

const GetOfficialInformationAboutUkraine: React.FC = () => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const leftColumn: InfoColumnSection[] = t('pages.officialInfo.leftCol', {
    returnObjects: true,
  });
  const rightColumn: InfoColumnSection[] = t('pages.officialInfo.rightCol', {
    returnObjects: true,
  });

  const mobileContent = [...leftColumn, ...rightColumn];

  return (
    <Layout>
      <Helmet>
        <meta name="keywords" content={t('pages.officialInfo.metaKeywords')} />
        <meta name="description" content={t('pages.officialInfo.metaDescription')} />
        <title>{t('pages.officialInfo.documentTitle')}</title>
      </Helmet>
      <div className="container--fluid">
        <h1>{t('pages.officialInfo.title')}</h1>
        <div className="column-container">
          {isMobile ? (
            <MobileInfoSection mobileContent={mobileContent} />
          ) : (
            <>
              <InfoColumn column={leftColumn} key="leftColumn" />
              <InfoColumn column={rightColumn} key="rightColumn" />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default GetOfficialInformationAboutUkraine;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
