import * as React from 'react';
import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import {
  InfoColumnSection,
  InfoSection,
} from '@components/info-column/info-column';
import './mobile-info-section.scss';

export const MobileInfoSection: React.FC<{
  mobileContent: InfoColumnSection[];
}> = ({ mobileContent }) => {
  const [currentSection, setCurrentSection] = React.useState(0);
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const sectionHeadingList = mobileContent.map(
    (section) => section.sectionHeading,
  );

  const sectionToRender = React.useMemo(
    () => mobileContent[currentSection],
    [currentSection, mobileContent],
  );

  return (
    <>
      <h2
        onClick={() => setMenuOpen(!isMenuOpen)}
        className={classNames('info-menu-opener', { active: isMenuOpen })}
        role="presentation"
      >
        <Trans
          i18nKey={sectionHeadingList[currentSection]}
          components={{ highlight: <span /> }}
        />
      </h2>
      {isMenuOpen && (
        <ul className="info-menu">
          {sectionHeadingList.map((heading, index) => (
            <li
              key={heading}
              onClick={() => setCurrentSection(index)}
              className={classNames('info-menu__item', { active: index === currentSection })}
              role="presentation"
            >
              <Trans i18nKey={heading} components={{ highlight: <span /> }} />
            </li>
          ))}
        </ul>
      )}
      <InfoSection sections={sectionToRender.sectionsList} />
    </>
  );
};
