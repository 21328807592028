import * as React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import { Highlight } from '../ui/highlight/highlight';
import './info-column.scss';

type InfoColumnListItem = {
  listHeading: string;
  listItems: Record<'content', string>[];
};

export type InfoColumnSection = {
  sectionHeading: string;
  sectionsList: InfoColumnListItem[];
};

export interface InfoColumnProps {
  column: InfoColumnSection[];
}

export const InfoSection: React.FC<{ sections: InfoColumnListItem[] }> = ({
  sections,
}) => (
  <ul className="section-list">
    {sections.map(({ listHeading, listItems }) => (
      <li key={listHeading}>
        <h3>{listHeading}</h3>
        <ul className="items-list">
          {listItems.map(({ content }) => (
            <li key={content} className="items-list__item">
              <Trans
                i18nKey={content}
                components={{
                  a: <a target="_blank" className="link--normal" />,
                }}
              />
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
);

export const InfoColumn: React.FC<InfoColumnProps> = ({ column }) => (
  <div className="column">
    {column.map((section) => (
      <article key={section.sectionHeading}>
        <h2>
          <Trans
            i18nKey={section.sectionHeading}
            components={{ highlight: <Highlight /> }}
          />
        </h2>
        <InfoSection sections={section.sectionsList} />
      </article>
    ))}
  </div>
);
